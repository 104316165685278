.mainContainer {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;

  width: 100%;
  min-height: 56px;

  padding-left: theme(spacing.4);
  padding-right: theme(spacing.4);
  background-color: white;

  box-shadow: theme(boxShadow.2);
}

.title {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 !important;
  padding-top: theme(spacing.4) !important;
  padding-bottom: theme(spacing.4) !important;

  margin: 0;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  display: flex;
  align-items: center;

  cursor: pointer;
}

.icon {
  margin-right: theme(spacing.3);
  color: theme(colors.tertiary500);
}

.expandable {
  display: block;
  width: 100%;
  overflow: hidden;
  transition: 0.4s ease-in-out;
}

.close {
  max-height: 0;
}

.childContainer {
  padding: theme('spacing.4');
}

.chevron {
  position: absolute;
  right: 0;
  justify-self: end;
  color: theme(colors.tertiary500);
}

.titleText {
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: theme(colors.tertiary500);
}

.placeholder {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 0px;
  gap: 8px;
  width: 100%;
  height: 140px;

  background: #f5f6f9;
  color: #7b61ff;

  border: 1px dashed #a8adb7;
  border-radius: 8px;

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}

.placeholderGrid {
  transform: rotate(45deg);
  margin-right: theme(spacing.1);
  width: 16.97px;
}
