.divider {
  display: flex;
  align-items: center;
  text-align: center;
}

[data-weight='thin'].divider {
  &::before,
  &::after {
    border-width: 1px;
  }
}

[data-weight='thick'].divider {
  &::before,
  &::after {
    border-width: 4px;
  }
}

[data-variant='normal'].divider {
  &::before,
  &::after {
    content: '';
    flex: 1;
    border-color: theme('colors.tertiary50');
  }

  &:not(:empty)::before {
    margin-right: theme('spacing.2');
  }

  &:not(:empty)::after {
    margin-left: theme('spacing.2');
  }
}

[data-variant='bordered'].divider {
  border-top: 1px solid theme('colors.tertiary50');
  border-bottom: 1px solid theme('colors.tertiary50');
  display: inline-flex;
  justify-content: center;
  padding: theme('spacing.2') 0;
}

[data-variant='fill'].divider {
  background-color: theme('colors.tertiary50');
  display: inline-flex;
  justify-content: center;
  padding: theme('spacing.2') 0;
  border-radius: theme('borderRadius.4');
}
