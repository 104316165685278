.headerContainer {
  position: absolute;
  top: 16px;
  left: 21px;
  right: 21px;
  z-index: 101;
  width: 94%;
}
.pinPoint {
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}
.tooltip-container {
  position: absolute;
  top: 33%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}
.blockingTag {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
}
.tooltip {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  position: relative;
  background: #d22b50;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.tooltip::after {
  position: absolute;
  bottom: -14px;
  left: 50%;
  transform: translate(-50%, -50%);
  content: " ";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 12px solid #d22b50;
}
