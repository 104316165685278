.input {
  box-sizing: border-box;
  width: 100%;
  background: #ffffff;
  text-overflow: ellipsis;
}
.input-with-icon-left {
  padding: 7px 38px 7px 40px;
}
.input-without-icon-left {
  padding: 7px 38px 7px 8px;
}
.menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 0px;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 2px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2);
  flex: none;
  order: 1;
  flex-grow: 0;
  z-index: 50;
  border-radius: 8px;
}
.list-menu {
  position: relative;
  display: flex;
  padding-left: 38px;
  padding-right: 18px;
  width: 100%;
  cursor: pointer;
}
