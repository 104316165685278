.overlay {
  background-color: hsl(0 0% 0% / 0.439); /* Copied from Radix sample */
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 99999;
}

.content {
  max-height: 100vh !important;
  background-color: white;
  border-radius: 16px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: theme(spacing.6);
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  z-index: 99999;
}

.title {
  @apply text-body-lg-bold;
  flex: 1;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;

  & h2 {
    margin: 0;
    align-items: center;
    display: inline-flex;
  }
}

.closeButton {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: theme(borderRadius.full);

  &:hover {
    background-color: theme(colors.tertiary200);
  }

  &:active {
    background-color: theme(colors.tertiary300);
  }
}
