.alert {
  box-sizing: border-box;
  border-radius: theme('borderRadius.2');
  padding: theme('spacing.4');
  border-width: 1px 1px 1px 4px;
  border-style: solid;
  display: flex;
  position: relative;
}

[data-variant='info'].alert {
  background-color: theme('colors.information25');
  border-color: theme('colors.information500');
}

[data-variant='warning'].alert {
  background-color: theme('colors.warning25');
  border-color: theme('colors.warning500');
}

[data-variant='error'].alert {
  background-color: theme('colors.error25');
  border-color: theme('colors.error500');
}

[data-variant='success'].alert {
  background-color: theme('colors.success25');
  border-color: theme('colors.success500');
}

.title {
  @apply text-body-sm-bold;
  margin-bottom: theme('spacing.1');
  color: theme('colors.tertiary500');
}

.description {
  @apply text-caption-lg-regular;
  color: theme('colors.tertiary500');
}

.icon {
  height: theme('spacing.6');
  width: theme('spacing.6');
  margin-right: theme('spacing.3');
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.icon svg {
  color: white;
  width: theme('spacing.4');
  max-height: theme('spacing.4');
}

[data-variant='info'] {
  .icon {
    background-color: theme('colors.information500');
  }
}

[data-variant='warning'] {
  .icon {
    background-color: theme('colors.warning500');
  }
}

[data-variant='error'] {
  .icon {
    background-color: theme('colors.error500');
  }
}

[data-variant='success'] {
  .icon {
    background-color: theme('colors.success500');
  }
}

.closeIcon {
  position: absolute;
  cursor: pointer;
  top: theme('spacing.4');
  right: theme('spacing.4');
}
