.btn-map {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 8px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #e2e4e8;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  position: absolute;
  z-index: 999;
  right: 16px;
  bottom: 16px;
  font-size: 12px;
  font-weight: 600;
}

.btn-transparent:hover {
  background-color: transparent !important;
}

.btn-transparent:focus {
  outline: none !important;
  box-shadow: none !important;
}
